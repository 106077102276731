.header {
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header .mode {
}
.header .user-name {
	line-height: 30px;
}
