.input-img [type='file'] {
	display: none;
}

.input-img label {
	position: absolute;
	right: 7px;
	top: 7px;
	border-radius: 50%;
	background-color: #fff;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	height: 1.5rem;
	width: 1.5rem;
	background-position: center;
	background-size: 70%;
	background-repeat: no-repeat;
}

.input-img .del {
	position: absolute;
	left: 7px;
	top: 7px;
	border: none;
	margin: 0px;
	background-color: #fff;
	color: #fff;
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 70%;
	cursor: pointer;
	display: inline-block;
}

.input-img .del:hover,
.input-img label:hover {
	background-color: #f3f3f3;
	border: 2px solid #f3f3f3;
}

.input-img {
	border: 2px solid #f3f3f3;
	border-radius: 15px;
	padding: 5px;
	margin: 20px 0;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.input-img .title {
	padding: 0 17px;
}

.input-img .title {
	position: absolute;
	background-color: #fff;
	top: -15px;
	left: 10px;
	padding: 0 5px;
}

.input-img img {
	border-radius: 10px;
	margin: 3px;
	height: 100%;
	width: 100%;
	
}

.scale {
	object-fit: contain;
}
.not-scale {
	object-fit: none;
}