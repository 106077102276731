.cmptbl-com-cnt-item {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.cmptbl-com-cnt-item:hover {
	color: #5aba0c;
	text-decoration: underline;
}
.cmptbl-head-com-cnt-item-tit {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
}
