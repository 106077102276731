.ms-ttl-right {
	display: flex;
	align-items: center;
}
.ms-ttl-right > div {
	display: flex;
	align-items: center;
	margin-right: 70px;
}
.ms-ttl-right > span {
	background-size: 12px;
}
