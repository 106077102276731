.mess-cnt-title {
	display: flex;
	align-items: center;
	height: 50px;
	padding-left: 20px;
	padding-right: 20px;
	color: #fff;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	background-color: #F87A60;
	border-radius: 20px 20px 0px 0px;
}
.mess-cnt-title p {
	padding-left: 30px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 20px;
}
