.footer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
	padding-right: 20px;
	padding-left: 10px;
}
