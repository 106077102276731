.moment {
	min-width: 50px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.user-name {
	min-width: 50px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.header-right {
	padding-right: 10px;
}