.dct-cnt-main {
	overflow-y: auto;
	max-height: 70vh;
}
.dct-cnt-main th,
.dct-cnt-main td {
	vertical-align: top;
}
.dct-cnt-main td {
}
