.command {
}
.command form {
	display: flex;
	align-items: center;
}
.command button {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	border: 0;
	border-radius: 50%;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px;
}
.command input {
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 5px;
	border: 0;
	border-radius: 20px;
}
