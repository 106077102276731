.master .cmp-page {
	height: calc(100% - 80px);
	min-height: 200px;
	border: 2px solid #f3f3f3;
	border-radius: 15px;
	padding: 10px;
	overflow-y: auto;
	overflow-x: hidden;
}

.cmp-page + .cmp-page {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 2px solid #e5e5e5;
}
