.dct-content {
	position: absolute;
	top: 10px;
	right: 80px;
	padding: 30px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}
.dct-close {
	position: absolute;
	top: 0;
	right: -60px;
	width: 50px;
	height: 50px;
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	cursor: pointer;
}
