.mess-content {
	position: relative;
	min-width: 300px;
	background-color: #fff;
	border-radius: 20px;

}
.mess-cnt-close {
	position: absolute;
	top: 0;
	right: -55px;
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
	cursor: pointer;
}
