.pg-mn-head {
	height: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 10px;
}
.pg-mn-head p {
	padding-left: 20px;
	color: #000;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 16px;
	cursor: pointer;
}
.pg-mn-head p:hover {
	text-decoration: underline;
}
