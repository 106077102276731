.flds-fld + .flds-fld {
	margin-top: 24px;
}
.flds-fld > p {
	margin-left: 10px;
	margin-bottom: 6px;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
}
.flds-fld > div {
	height: 40px;
	background: #F3F3F3;
	border-radius: 20px;
	background-position: 98% center;
	background-repeat: no-repeat;
	background-size: 14px;
}
.flds-fld label {
	display: flex;
	height: 100%;
	margin-left: 10px;
	margin-right: 30px;
}
.flds-fld input {
	flex-grow: 1;
	border: 0;
	background-color: transparent;
}
