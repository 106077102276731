.info-content-main-item {

}
.info-content-main-item .info-topic {
	font-weight: bold;
}
.info-content-main-item .info-value {
	font-family: 'Courier New';
}
.info-content-main-item .info-title {
	padding-right: 10px;
}
