.dct-cnt-head {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.dct-cnt-head p {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
}
.dct-cnt-head div {
	margin-left: 20px;
	padding-top: 5px;
	padding-left: 20px;
	padding-right: 40px;
	padding-bottom: 5px;
	background-color: #F3F3F3;
	background-position: 230px center;
	background-repeat: no-repeat;
	background-size: 12px;
	border-radius: 18px;
}
.dct-cnt-head input {
	width: 200px;
	border: none;
	background-color: inherit;
}
