.ms-ttl-right-page-dlm {
	display: flex;
	align-items: center;
	height: 30px;
	padding-right: 3px;
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 8px;
}
.ms-ttl-right-page-dlm div {
	width: 70px;
	height: 1px;
	background-color: #B5B5B5;
}
