.login-box .title {
	position: relative;
	height: 80px;
	margin-bottom: 20px;
	border-radius: 20px 20px 0 0;
}
.login-box .title div {
	position: absolute;
	top: 20px;
	left: 20px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
}
.login-box .title p {
	position: absolute;
	top: 23px;
	left: 80px;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
}
