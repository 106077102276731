.space {
	width: 100vw;
	height: 100vh;
}
.skin-shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.2;
}
.skin-wait {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 120px;
	height: 120px;
	margin-top: -60px;
	margin-left: -60px;
}
