.dct-cnt-main-head {
	position: sticky;
  	top: 0;
	background-color: white;
	width: 100%;
}
.dct-cnt-main-head th {
	color: #B5B5B5;
	font-weight: normal;
	text-align: left;
}
