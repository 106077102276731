.input-area ~ .cmp-field {
	width: 100%;
}
.area {
	background-color: #f3f3f3;
	border: 2px solid #f3f3f3;
	border-radius: 20px;
	padding: 5px 10px;
	margin: 4px 0;
	/* width: 100%; */
}
.area textarea {
	border: none;
	background-color: #f3f3f3;
	resize: none;
	width: 100%;
}

/* .boxsizingBorder {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
} */
