.tbl-head-com-lst-item {
	padding-left: 20px;
	cursor: pointer;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 10px;
	border-bottom: 1px solid #E5E5E5
}
.tbl-head-com-lst-item:hover {
	text-decoration: underline;
}
.tbl-head-com-lst-item.on {
	color: #5ABA0C;
}
.tbl-head-com-lst-item + .tbl-head-ttl-com-lst-item {
	margin-top: 5px;
}
