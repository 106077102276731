.page {
	position: relative;
	width: 100vw;
	height: 100vh;
	min-height: 500px;
}
.main {
	height: calc(100% - 50px - 40px - 50px);
}
.sandbox {
	position: relative;
	height: 100%;
	margin-left: 300px;
	padding-right: 20px;
	transition-property: margin-left;
	transition-duration: 0.5s;
}
.sandbox.full {
	margin-left: 60px;
}
