.login-box {
	position: relative;
	width: 100vw;
	height: 100vh;
}
.login-box .shadow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	/* background-color: #fff; */
	/* opacity: 0.8; */
}
.login-box .container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
}
