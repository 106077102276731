.form-head-ttl-com-view {
  color: #ee5130;
  padding-right: 15px;
  background-position: right 9px;
  background-repeat: no-repeat;
  background-size: 8px;
  cursor: pointer;
}
.form-head-ttl-com-view:hover {
  text-decoration: underline;
}
