.cmp-table .tbl-main-head {
	height: unset;
}
.cmp-table .tbl-main-head > table {
	width: 100%;
}
.cmp-table .tbl-main-head > table th {
	text-align: left;
	vertical-align: top;
	padding-top: 3px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 3px;
}
